'use strict';

angular.module('enervexSalesappApp').factory('LocationData', function ($resource) {
	return $resource('/api/location-datas/:id/:import', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		},
		import: {
			method: 'POST',
			timeout: 340000,
			transformRequest: angular.identity,
			headers: {
				'Content-Type': undefined,
				'Accept': "application/json"
			},
			params:{
				import: "import"
			}
		},
	});
});
